// extracted by mini-css-extract-plugin
export var AccountMessage = "Receipt-module--AccountMessage--a7bfa";
export var Active = "Receipt-module--Active--849b1";
export var Advice = "Receipt-module--Advice--9cd29";
export var AdviceBlock = "Receipt-module--AdviceBlock--f0278";
export var Button = "Receipt-module--Button--f6069";
export var Checkbox = "Receipt-module--Checkbox--c276e";
export var Empty = "Receipt-module--Empty--b60f3";
export var Error = "Receipt-module--Error--1a8bd";
export var Half = "Receipt-module--Half--1c7c9";
export var Highlight = "Receipt-module--Highlight--2fdf3";
export var Input = "Receipt-module--Input--8d52a";
export var Loading = "Receipt-module--Loading--ec9b7";
export var LoadingWheel = "Receipt-module--LoadingWheel--8f41e";
export var Message = "Receipt-module--Message--1dd86";
export var Pay = "Receipt-module--Pay--08543";
export var PayBlock = "Receipt-module--PayBlock--1f893";
export var Prices = "Receipt-module--Prices--f64bf";
export var PricesBlock = "Receipt-module--PricesBlock--82ef6";
export var Selected = "Receipt-module--Selected--a62cb";
export var Silver = "Receipt-module--Silver--51271";
export var Subscribe = "Receipt-module--Subscribe--aaad0";
export var SubscribeInner = "Receipt-module--SubscribeInner--1bab7";
export var SubscribeMain = "Receipt-module--SubscribeMain--b231d";
export var SubscribeSide = "Receipt-module--SubscribeSide--3ba05";
export var Tabs = "Receipt-module--Tabs--4dfe8";
export var Title = "Receipt-module--Title--f6a4e";
export var User = "Receipt-module--User--e2894";