import React from "react"
import { Package } from "../Classes/Package";
import Layout from "./Layout/layout"
import Receipt from "../Views/Website/Receipt/Receipt";

const ReceiptPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;
  const search = props.location.search ? props.location.search.replace("?", "").split("&") : "";
  const PackageID = search ? search.find(p => p.split("=")[0] === "PackageID").split("=")[1] : "";
  const PackageLength = search ? search.find(p => p.split("=")[0] === "PackageLength").split("=")[1] : "";
  const AdviceID = search ? search.find(p => p.split("=")[0] === "AdviceID").split("=")[1] : "";
  const AdviceLength = search ? search.find(p => p.split("=")[0] === "AdviceLength").split("=")[1] : "";

  return (
    <Layout>
      <Receipt Packages={Packages} PackageID={PackageID} Length={PackageLength} AdviceLine={AdviceID} AdviceLineLength={AdviceLength} />
    </Layout>
  );
};

export default ReceiptPage;