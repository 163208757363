import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AdviceLine } from '../../../Classes/AdviceLine';
import { Package } from '../../../Classes/Package';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Loading } from '../../../components/Loading/Loading';
import LoginForm from '../../../components/Login/Login';
import RegisterForm from '../../../components/Register/Register';
import UserProfile from '../../../components/User/User';
import { UserContext } from '../../../Context/UserContext';
import { API_AdviceLines, API_BaseURL, API_SquareCreateSubscription } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from './Receipt.module.scss';
import { User } from '../../../Classes/User';
import { Decrypt, parseJwt } from '../../../Services/Crypto';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

interface Props {
  PackageID: string;
  Length: string;
  AdviceLine: string;
  AdviceLineLength: string;
  Packages: Package[];
}

type SubPackage = {
  PackageID: number;
  PackageLength: number;
  AdviceID: number;
  AdviceLength: number;
  BankID: string;
}

const Receipt: React.FC<Props> = ({ PackageID, Length, AdviceLine, AdviceLineLength, Packages }) => {
  const [profile, setProfile] = useState<User>({} as User);
  const [pack, setPackage] = useState<Package>({} as Package);
  const [adviceLine, setAdviceLine] = useState<AdviceLine | undefined>(undefined);
  const [isAccount, setIsAccount] = useState<boolean>(true);
  const [messages, setMessages] = useState<any[]>([] as any[]);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { User, setUser } = useContext(UserContext);

  useMemo(() => {
    if (typeof window !== 'undefined') {
      let token = localStorage.getItem("User");
      if (token) {
        let jwt = parseJwt(Decrypt(token));
        setProfile({
          ID: parseInt(jwt.nameid),
          isAdmin: jwt.role.includes("ADMIN") ? jwt.role.split(":")[1] === "true" : false,
          isEmployee: jwt.role.includes("EMPLOYEE"),
          isConsultant: jwt.role.includes("CONSULTANT"),
          Email: jwt.email,
          Name: jwt.unique_name
        } as User)
      }
    }
  }, [])

  useEffect(() => {
    if (User)
      setProfile(User);
  }, [User])

  useEffect(() => {
    GetData();
  }, [PackageID, Length, AdviceLine, AdviceLineLength])

  useEffect(() => {
    let mess: any[] = [] as any[];
    if (User.ID) {
      if (User.Subscription) {
        if ((User.Subscription.isGold || User.Subscription.isSilver) && (parseInt(PackageID))) {
          mess.push("You already have a membership");
        }

        if (User.Subscription.hasAdviceLine && (parseInt(AdviceLine))) {
          mess.push("You already have an Advice Line Subscription");
        }
      }

      if (User.isConsultant)
        mess.push(<>{`You are currently logged in as a Consultant. `} <Link to={`/Subscribe/Consultant`}>Click Here</Link> to subscribe.</>);

      if (User.isEmployee)
        mess.push("You are currently logged in as an Employee");
    }
    setMessages(mess);
  }, [User]);

  const GetData = async () => {
    setLoading(true);

    if (PackageID) {
      let chosenPack = Packages.find(p => p.ID === parseInt(PackageID))
      if (chosenPack)
        setPackage(chosenPack);
    }

    if (AdviceLine && parseInt(AdviceLine))
      await Fetch(`${API_AdviceLines}`)
        .then((Response: AdviceLine[]) => {
          setAdviceLine(Response.find(x => x.ID === parseInt(AdviceLine)));
        })
    else
      setAdviceLine(undefined);

    setLoading(false);
  }

  const AdjustNumber = (Value: number) => {
    return (Math.round(Value * 100) / 100).toFixed(2)
  }

  const SubscribeViaSquare = async (token: any) => {
    const data: SubPackage = {
      PackageID: parseInt(PackageID),
      PackageLength: parseInt(Length),
      AdviceID: parseInt(AdviceLine),
      AdviceLength: parseInt(AdviceLineLength),
      BankID: token
    }

    setSubmitting(true);
    await Fetch(`${API_SquareCreateSubscription}`, data)
      .then((Response: User) => {
        if (Response.ID) {
          setUser(Response);
          setSuccess(true);
        }
      }).catch(() => {
        return;
      })
    setSubmitting(false);
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Reciept</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={[
      { To: `/Subscribe?PackageID=${PackageID}&PackageLength=${Length}&AdviceID=${AdviceLine}&AdviceLength=${AdviceLineLength}`, Text: "Subscribe" },
      { To: "", Text: "Receipt" }
    ]} />

    {!success ? <div className={styles.Subscribe}>
      {loading ? <Loading /> : <></>}

      <div className={styles.SubscribeInner}>
        <div className={styles.SubscribeMain}>
          {profile.ID ? <div className={styles.User}>
            <h2>Continue As:</h2>
            <UserProfile />

            <h1>OR</h1>
          </div> : <></>}

          <div className={styles.Tabs}>
            <button className={isAccount ? styles.Active : ""} onClick={() => { setIsAccount(true); }}>
              I Have An Account
            </button>

            <button className={!isAccount ? styles.Active : ""} onClick={() => { setIsAccount(false); }}>
              I Don't Have An Account
            </button>
          </div>

          {isAccount ? <LoginForm /> : <RegisterForm />}
        </div>
      </div>

      <div className={styles.SubscribeSide}>
        <h1 className={styles.Title}>Purchasing: </h1>

        <div className={`${styles.Advice}`}>
          {adviceLine ? <>
            <div className={`${styles.AdviceBlock}`}>
              <h2>
                <b>
                  Advice Line
                  <small>Reoccuring {AdviceLineLength === "12" ? "12 Months" : "1 Month"}</small>
                </b>
                <span>
                  £{AdviceLineLength === "12" ? AdjustNumber(parseFloat(adviceLine.YearPrice)) : AdjustNumber(parseFloat(adviceLine.MonthPrice))}
                </span>
              </h2>
            </div>
          </> : <></>}

          {pack.ID ? <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>
                {pack.Title}
                <small>
                  Reoccuring {Length} Month{parseInt(Length) < 1 ? "s" : ""}
                </small>
              </b>
              <span>
                £{Length === "12" ? AdjustNumber(parseFloat(pack.Cost12) * 12) : Length === "6" ? AdjustNumber(parseFloat(pack.Cost6) * 6) : Length === "3" ? AdjustNumber(parseFloat(pack.Cost3) * 3) : AdjustNumber(parseFloat(pack.Cost))}
              </span>
            </h2>
          </div> : <></>}

          <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>Total</b>
              <span>
                £{(adviceLine ? (AdviceLineLength === "12" ? (parseFloat(adviceLine.YearPrice)) : (parseFloat(adviceLine.MonthPrice))) : 0) + (pack.ID ? (Length === "12" ? (parseFloat(pack.Cost12) * 12) : Length === "6" ? (parseFloat(pack.Cost6) * 6) : Length === "3" ? (parseFloat(pack.Cost3) * 3) : (parseFloat(pack.Cost))) : 0)}
              </span>
            </h2>
          </div>

          <div className={`${styles.AdviceBlock}`}>
            <h2>
              <b>Incl. VAT (20%)</b>
              <span>
                £{AdjustNumber(((adviceLine ? (AdviceLineLength === "12" ? (parseFloat(adviceLine.YearPrice)) : (parseFloat(adviceLine.MonthPrice))) : 0) + (pack.ID ? (Length === "12" ? (parseFloat(pack.Cost12) * 12) : Length === "6" ? (parseFloat(pack.Cost6) * 6) : Length === "3" ? (parseFloat(pack.Cost3) * 3) : (parseFloat(pack.Cost))) : 0)) * 1.2)}
              </span>
            </h2>
          </div>

          <div className={`${styles.AdviceBlock} ${submitting ? styles.Loading : ""}`}>
            {User.ID ? messages.length ? <>
              {
                messages.map(message => {
                  return <div className={styles.Error}>
                    <p>{message}</p>
                  </div>
                })
              }
            </> : <>
              {submitting ? <Loading /> :
                <PaymentForm
                  applicationId={API_BaseURL === "/" ? "sq0idp-XJnj35l4gr1O-c6wSC2LQQ" : "sandbox-sq0idb-58Lfiex_YCefaJxIiskU1w"}
                  cardTokenizeResponseReceived={(token, buyer) => {
                    console.info({ token, buyer });
                    SubscribeViaSquare(token.token);
                  }}
                  createVerificationDetails={() => ({
                    amount: '1.00',
                    /* collected from the buyer */
                    billingContact: {
                      addressLines: [''],
                      familyName: User.Surname,
                      givenName: User.Forename,
                      countryCode: 'GB',
                      city: '',
                    },
                    currencyCode: 'GBP',
                    intent: 'CHARGE',
                  })}
                  locationId={API_BaseURL === "/" ? "LPMPP77K32X39" : "LZYXW9FJKDCTE"}
                >
                  <CreditCard />
                </PaymentForm>
              }
            </> : <div className={styles.AccountMessage}>
              Please Sign In or Create An Account to Continue
            </div>}
          </div>
        </div>
      </div>
    </div> : <div className={styles.Message}>
      <h1>You Have Successfully Subscribed To {PackageID && Length ? pack.Title : ""}{PackageID && Length && adviceLine ? " and " : ""}{adviceLine ? `${adviceLine?.Title} Employees` : ""}!</h1>
      <i>
        <FontAwesomeIcon icon={faCheckCircle} />
      </i>
      <Link to={"/Portal"} className={styles.Button}>
        Start Using Now
      </Link>
    </div>}
  </>
  );
};

export default Receipt;
