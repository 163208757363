import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AdviceLine } from "../../Classes/AdviceLine";
import { OpeningTimes, TimeLoggingOption, User } from "../../Classes/User";
import { API_AdviceLines, API_CreateAccount } from "../../Services/ApiRoutes";
import { Fetch } from "../../Services/Fetch";
import * as styles from "./Register.module.scss";
import { UserContext } from "../../Context/UserContext";
import { Loading } from "../Loading/Loading";
import PasswordStrengthBar from 'react-password-strength-bar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faArrowLeft, faArrowRight, faBusinessTime, faCheckDouble, faFaceFrown, faFaceGrinBeam, faFaceMeh, faFaceSadTear, faFaceSmile, faGraduationCap, faHamburger, faHandshake, faPauseCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Terms from "../Terms/Terms";
import Privacy from "../Privacy/Privacy";
import { Encrypt, EncryptData, EncryptValue } from "../../Services/Crypto";
import { Link } from "gatsby";
import Input, { Option } from "../Input/input";
import { Button } from "../../Views/Portal/Courses/Components/Button/Button";
import { WorkStatus } from "../../Enums/EmploymentStatus";

const RegisterForm: React.FC = () => {
  const { setUser } = useContext(UserContext);
  const [userForm, setUserForm] = useState<User>({
    Title: "",
    Forename: "",
    Surname: "",
    Company: "",
    Employees: "",
    Phone: "",
    Email: "",
    Password: ""
  } as User)
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [adviceLines, setAdviceLines] = useState<AdviceLine[]>([] as AdviceLine[]);
  const [captcha, setCaptcha] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [terms, setTerms] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [passwordStength, setPasswordStength] = useState<number>(0);
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [openPrivacy, setOpenPrivacy] = useState<boolean>(false);
  const [section, setSection] = useState<number>(0);

  const status: WorkStatus[] = [WorkStatus.ON_BREAK, WorkStatus.ON_LUNCH, WorkStatus.OVERTIME, WorkStatus.TRAINING, WorkStatus.IN_MEETING] as WorkStatus[];
  const icons: IconDefinition[] = [faPauseCircle, faHamburger, faBusinessTime, faGraduationCap, faHandshake];
  const colours: string[] = ["#ff9800", "#ff5722", "#00bcd4", "#4caf50", "#ffeb3b"];

  const happinessList: string[] = ["Upset", "Unhappy", "Average", "Happy", "Excited"];
  const happinessIcons: IconDefinition[] = [faFaceSadTear, faFaceFrown, faFaceMeh, faFaceSmile, faFaceGrinBeam];
  const happinessColours: string[] = ["#f44336", "#ff9800", "#ffc107", "#8bc34a", "#4caf50"];

  useEffect(() => {
    setMessage("");
  }, [section])

  useEffect(() => {
    GetData();
  }, [])

  const GetData = async () => {
    setLoading(true);
    await Fetch(`${API_AdviceLines}`)
      .then((Response: AdviceLine[]) => {
        setAdviceLines(Response);
      })
    setLoading(false);
  }

  const Submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (section === 0) {
      if (userForm.Forename && userForm.Surname)
        setSection(section + 1);
      else
        setMessage("Please fill in all required (*) fields");
    }

    if (section === 1) {
      if (userForm.Company && userForm.Employees && userForm.Phone)
        setSection(section + 1);
      else
        setMessage("Please fill in all required (*) fields");
    }

    userForm.TimeLoggingOptionsData = status.map((status: WorkStatus, index: number) => {
      return {
        Name: status,
        Colour: colours[index],
        Icon: icons[index]
      } as TimeLoggingOption;
    });

    userForm.HappinessOptionsData = happinessList.map((status: string, index: number) => {
      return {
        Name: status,
        Colour: happinessColours[index],
        Icon: happinessIcons[index]
      } as TimeLoggingOption;
    });

    if (section === 2) {
      if (userForm.Email && email && userForm.Password && password) {
        if (terms) {
          if ((userForm.Email === email)) {
            if ((userForm.Password === password)) {
              if (captcha)
                await Fetch(`${API_CreateAccount}`, {
                  ...userForm, Password: Encrypt(userForm.Password),
                  OpeningTimes: JSON.stringify({} as OpeningTimes),
                  TimeLoggingOptions: JSON.stringify(userForm.TimeLoggingOptionsData),
                  HappinessOptions: JSON.stringify(userForm.HappinessOptionsData),
                  Glossary: JSON.stringify({})
                } as User)
                  .then(u => {
                    if (u.User.ID) {
                      localStorage.setItem("User", EncryptData(u["Token"]));
                      setUser(u["User"]);
                      setUserForm({
                        Title: "",
                        Forename: "",
                        Surname: "",
                        Company: "",
                        Employees: "-1",
                        Phone: "",
                        Email: "",
                        Password: ""
                      } as User);

                      setEmail("");
                      setPassword("");
                      setCaptcha("");
                    } else {
                      setMessage("An account with that email already exists.");
                      setLoading(false);
                    }
                  }).catch((err) => {
                    setMessage(err.ExceptionMessage);
                    setLoading(false);
                  })
            } else {
              setMessage("Passwords do not match");
            }
          } else {
            setMessage("Emails do not match");
          }
        } else {
          setMessage("Please confirm that you have read and agree to the Terms & Conditions and Privacy Policy");
        }
      } else {
        setMessage("Please fill in all required (*) fields");
      }
    }

    setLoading(false);
  }

  const onChange = (value: any) => {
    setCaptcha(value);
  }

  return (<>
    {loading ? <Loading /> : <></>}

    <div className={`${styles.Modal} ${openTerms ? styles.Show : ""}`}>
      <div className={styles.ModalBackground} onClick={() => setOpenTerms(false)}></div>
      <div className={styles.ModalContent}>
        <div className={styles.ModalHeader}>
          <h1>Terms & Conditions for an Annual Subscription</h1>
          <button onClick={() => setOpenTerms(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div className={styles.ModalBody}>
          <Terms hideBanner={true} />
        </div>

        <div className={styles.ModalFooter}>
          <button type="button" onClick={() => { setOpenTerms(false) }}>
            Cancel
          </button>

          <button type="button" className={styles.Green} onClick={() => { setTerms(true); setOpenTerms(false) }}>
            Agree To Terms
          </button>
        </div>
      </div>
    </div>

    <div className={styles.Header}>
      <h1>Create An Account</h1>

      <p className={section === 0 ? styles.Active : ""}>
        <b>{userForm.Forename && userForm.Surname && userForm.Title ?
          <FontAwesomeIcon icon={faCheckDouble} />
          : "1"}</b>
        Personal Information
      </p>
      <p className={section === 1 ? styles.Active : ""}>
        <b>{userForm.Company && userForm.Employees && userForm.Phone ?
          <FontAwesomeIcon icon={faCheckDouble} />
          : "2"}</b>
        Company Information
      </p>
      <p className={section === 2 ? styles.Active : ""}>
        <b>{captcha && terms && passwordStength >= 3 && password && userForm.Password && userForm.Email && email ?
          <FontAwesomeIcon icon={faCheckDouble} />
          : "3"}</b>
        Account Information
      </p>
    </div>

    {section === 0 ? <form className={styles.Form} onSubmit={(e) => Submit(e)}>
      <div className={`${styles.FormInner}`}>
        <h2>Personal Information</h2>
        <p>We generate your first Employee with your own Personal Information so you can get a feel for how the Portal works with your own data</p>

        <Input Type="select" Value={userForm.Title} OnChange={(v) => setUserForm({ ...userForm, Title: v })} Placeholder="..." Label="Title" Options={[{
          Text: "Mr",
          Value: "Mr"
        }, {
          Text: "Mrs",
          Value: "Mrs"
        }, {
          Text: "Miss",
          Value: "Miss"
        }, {
          Text: "Ms",
          Value: "Ms"
        }, {
          Text: "Mx (Gender Neutral)",
          Value: "Mx"
        }, {
          Text: "Doctor",
          Value: "Dr"
        }, {
          Text: "Professor",
          Value: "Professor"
        }]} />

        <Input Width="half" Required OnChange={(v) => setUserForm({ ...userForm, Forename: v })} Placeholder={"..."} Type={"text"} Label={"Forename"} Value={userForm.Forename} />
        <Input Width="half" Required OnChange={(v) => setUserForm({ ...userForm, Surname: v })} Placeholder={"..."} Type={"text"} Label={"Surname"} Value={userForm.Surname} />

        {message ? <div className={styles.Message}>
          {message}
        </div> : <></>}

        <div className={styles.Buttons}>
          <Button Type="submit" Color="Theme" Disabled={!userForm.Forename || !userForm.Surname || !userForm.Title}>
            Next
            <i>
              <FontAwesomeIcon icon={faArrowRight} />
            </i>
          </Button>
        </div>
      </div>
    </form> : <></>}

    {section === 1 ? <form className={styles.Form} onSubmit={(e) => Submit(e)}>
      <div className={`${styles.FormInner}`}>
        <h2>Company Information</h2>
        <p>This is your basic Company information. We use your Company Name to automatically add information to relevant areas of Document Templates</p>

        <Input Width="half" Required OnChange={(v) => setUserForm({ ...userForm, Company: v })} Placeholder={"..."} Type={"text"} Label={"Company Name"} Value={userForm.Company} />
        <Input Width="half" Required OnChange={(v) => setUserForm({ ...userForm, Phone: v })} Placeholder={"..."} Type={"text"} Label={"Phone Number"} Value={userForm.Phone} />

        <Input Required Type="select" Value={userForm.Employees} OnChange={(v) => setUserForm({ ...userForm, Employees: v })} Placeholder="..." Label="Number of Employees" Options={adviceLines.map(adv => {
          return { Value: `${adv.ID}`, Text: adv.ID === 1 ? "I am Self Employed or HR Consultant" : `${adv.Title} Employees` } as Option
        })} />

        {message ? <div className={styles.Message}>
          {message}
        </div> : <></>}


        <div className={styles.Buttons}>
          <Button Type="submit" Color="Theme" Disabled={!userForm.Company || !userForm.Employees || !userForm.Phone}>
            Next
            <i>
              <FontAwesomeIcon icon={faArrowRight} />
            </i>
          </Button>

          <Button Type="button" Color="Link" OnClick={() => setSection(0)}>
            <i>
              <FontAwesomeIcon icon={faArrowLeft} />
            </i>
            Previous
          </Button>
        </div>
      </div>
    </form> : <></>}

    {section === 2 ? <form className={styles.Form} onSubmit={(e) => Submit(e)}>
      <div className={`${styles.FormInner}`}>
        <h2>Account Information</h2>
        <p>This is your PrimeHR HR Admin Account Information. You will use this to log into the Portal and manage your Employees</p>

        <Input Width="half" Required OnChange={(v) => setUserForm({ ...userForm, Email: v })} Placeholder={"..."} Type={"email"} Label={"Email"} Value={userForm.Email} />
        <Input Width="half" Required OnChange={(v) => setEmail(v)} Placeholder={"..."} Type={"email"} Label={"Confirm Email"} Value={email} />

        <Input Width="half" Required OnChange={(v) => setUserForm({ ...userForm, Password: v })} Placeholder={"..."} Type={"password"} Label={"Password"} Value={userForm.Password} />
        <Input Width="half" Required OnChange={(v) => setPassword(v)} Placeholder={"..."} Type={"password"} Label={"Confirm Password"} Value={password} />

        <div className={styles.PasswordStength}>
          <PasswordStrengthBar password={userForm.Password} className={styles.PasswordStengthBar} onChangeScore={(e) => setPasswordStength(e)} />
        </div>

        <Link to={`/Login`}>Already Have An Account?</Link>

        <div className={`${styles.Checkbox}`}>
          <input checked={userForm.isMarketing} type="checkbox" onChange={(e) => setUserForm({ ...userForm, isMarketing: e.target.checked })} />
          <label>Yes, I would like to receive marketing materials from PrimeHR</label>
        </div>

        <div className={`${styles.Checkbox}`}>
          <input checked={terms} type="checkbox" onChange={(e) => setTerms(e.target.checked)} required />
          <label>Yes, I agree to the <a onClick={() => setOpenTerms(true)}>Terms and Conditions</a> and have read the <a onClick={() => setOpenPrivacy(true)}>Privacy Policy</a></label>
        </div>

        <div className="capcha">
          <ReCAPTCHA
            onExpired={() => { setMessage("RECAPTCHA has expired, please try again"); }}
            onErrored={() => { setMessage("An Error has Occurred on the RECAPTCHA, please try again"); }}
            sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { onChange(e); setMessage("") }} />
        </div>

        {message ? <div className={styles.Message}>
          {message}
        </div> : <></>}

        <div className={styles.Buttons}>
          <Button Type="submit" Color="Theme" Disabled={!captcha || !terms || passwordStength < 3 || !password || !userForm.Password || !userForm.Email || !email}>
            Create Account
          </Button>

          <Button Type="button" Color="Link" OnClick={() => setSection(1)}>
            <i>
              <FontAwesomeIcon icon={faArrowLeft} />
            </i>
            Previous
          </Button>
        </div>
      </div>
    </form> : <></>}

    <div className={`${styles.Modal} ${openPrivacy ? styles.Show : ""}`}>
      <div className={styles.ModalBackground} onClick={() => setOpenPrivacy(false)}></div>
      <div className={styles.ModalContent}>
        <div className={styles.ModalHeader}>
          <h1>Privacy Policy</h1>
          <button onClick={() => setOpenPrivacy(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div className={styles.ModalBody}>
          <Privacy hideBanner={true} />
        </div>
      </div>
    </div>
  </>);
};

export default RegisterForm;
